import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { shallowEqual, useSelector } from "react-redux";
import * as TYPE from './lib/contrant'


const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Dashboard = lazy(() =>
  import("./pages/Dashboard")
);

const ListUserAdmin = lazy(() =>
  import("./pages/Admin/ListUserAdmin")
);
const ListTask = lazy(() =>
  import("./pages/Task/ListTask")
);
const ListUserClient = lazy(() =>
  import("./pages/User/ListUser")
);
const ListUser = lazy(() =>
  import("./pages/User/ListUser")
);

const UserProfile = lazy(() =>
  import("./pages/User/UserProfile")
);

const ListTeam = lazy(() =>
  import("./pages/LeaderBoard/ListTeam")
)

const HNGDrops = lazy(() =>
  import("./pages/HNGDrops/index")
)

const HNGDropsDetail = lazy(() =>
  import("./pages/HNGDrops/detailDay")
)

const DetailTeam = lazy(() =>
  import("./pages/LeaderBoard/DetailTeam")
)

const ChangePassword = lazy(() =>
  import("./pages/User/ChangePassword")
);

const AdminProfile = lazy(() =>
  import("./pages/User/AdminProfile")
);

const AssetsManagement = lazy(() =>
  import("./pages/Assets/AssetsManagement")
);

const ListWithdrawToken = lazy(() =>
  import("./pages/Assets/ListWithdrawToken")
);

const HistoryManagement = lazy(() =>
  import("./pages/Assets/HistoryManagement")
);

const DailyCodeManagement = lazy(() =>
  import("./pages/DailyCode/DailyCodeManagement")
);

const CompletedDailyCodeList = lazy(() =>
  import("./pages/DailyCode/DailyCodeCompletedList")
);

const CompletedDailyCodeListDetail = lazy(() =>
  import("./pages/DailyCode/DailyCodeCompletedListDetail")
);

const ListGroupTaskCollab = lazy(() =>
  import("./pages/GroupCollab/index")
)

const ListTaskCollab = lazy(() =>
  import("./pages/TaskCollab/ListTaskCollab.jsx")
)

const RewardPrizecConfig = lazy(() => import("./pages/Config/RewardPrizeConfig.jsx"));
const RewardDailyConfig = lazy(() => import("./pages/Config/RewardDailyConfig.jsx"));
const MinValueWithdrawToken = lazy(() => import("./pages/Config/MinValueWithdrawToken.jsx"));

const ConfigureProgram = lazy(() =>
  import("./pages/LuckyMoney/ConfigureProgram")
)

const LuckyHistory = lazy(() => import("./pages/LuckyMoney/LuckyHistory"));

export default function BasePage() {
  const { listUserLoginPermission } = useSelector(state => ({
    listUserLoginPermission: state.authReducer.listUserLoginPermission,
  }), shallowEqual)

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {/* Trang chủ */}
        <Route path="/dashboard" component={Dashboard} exact />
        {/* end */}

        <Route path="/user-profile" component={UserProfilepage} exact />
        {<Route path="/assets/list" component={AssetsManagement} exact />}
        {<Route path="/assets/request-withdraw-token" component={ListWithdrawToken} exact />}
        {<Route path="/history/:id" component={HistoryManagement} exact />}
        {/* Quản lý người dùng */}
        {<Route path="/admin/list-user" component={ListUserAdmin} exact />}
        {/* Quản lý nhiệm vụ */}
        {<Route path="/task/list" component={ListTask} exact />}
        {<Route path="/task/collab/:id" component={ListTaskCollab} exact />}
        {<Route path="/task/group-collab/list" component={ListGroupTaskCollab} exact />}

        {listUserLoginPermission.includes(TYPE.QUAN_LY_NGUOI_DUNG) && <Route path="/user/list-user" component={ListUser} exact />}
        <Route path="/user/profile/:id" component={UserProfile} exact />

        {/* Quản lý bảng xếp hạng */}
        {<Route path="/team/list" component={ListTeam} exact />}
        {<Route path="/team/daily" component={HNGDrops} exact />}
        {<Route path="/team/daily/detail/:day" component={HNGDropsDetail} exact />}
        {<Route path="/team/:id" component={DetailTeam} exact />}

        {/* Quản lý người chơi */}
        {<Route path="/user/list" component={ListUserClient} exact />}
        {/* end */}

        {/* Quản lý admin */}
        {listUserLoginPermission.includes(TYPE.DOI_MAT_KHAU) && <Route path="/admin/change-password" component={ChangePassword} exact />}
        {listUserLoginPermission.includes(TYPE.TRANG_CA_NHAN_ADMIN) && <Route path="/admin/profile" component={AdminProfile} exact />}
        {/* end */}

        {/* Quản lý mật mã ngày */}
        <Route path="/daily-code/list" component={DailyCodeManagement} exact />
        <Route path="/daily-code/completed" component={CompletedDailyCodeList} exact />
        <Route path="/daily-code/completed/detail/:date" component={CompletedDailyCodeListDetail} exact />
        {/* end */}

        <Route
          path="/config/lucky-draw/list"
          component={ConfigureProgram}
        />
        <Route
          path="/config/lucky-draw/history/:configureProgramId"
          component={LuckyHistory}
        />

        {/* Quản lý cấu hình */}
        <Route path="/config/reward-prize" component={RewardPrizecConfig} exact />
        <Route path="/config/reward-daily" component={RewardDailyConfig} exact />
        <Route path="/config/min-value-withdraw-token" component={MinValueWithdrawToken} exact />
        {/* end */}
        <Redirect from="/login" to="/" />
      </Switch>
    </Suspense>
  );
}
