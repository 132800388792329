export const Types = {
  SAVE_LIST_USER_CLIENT: 'SAVE_LIST_USER_CLIENT',
  SAVE_USER_CLIENT_BY_ID: 'SAVE_USER_CLIENT_BY_ID',
  SAVE_STATISTIC_USER: 'SAVE_STATISTIC_USER',
  SAVE_USER_REFERRAL_BY_ID: 'SAVE_USER_REFERRAL_BY_ID',
  SAVE_LIST_REFERRAL: 'SAVE_LIST_REFERRAL',
  SAVE_HISTORY_CHANGE_COIN_BY_ID: 'SAVE_HISTORY_CHANGE_COIN_BY_ID',

  SAVE_LIST_USER_KYC: 'SAVE_LIST_USER_KYC',
  SAVE_USER_KYC: 'SAVE_USER_KYC',
  SAVE_LIST_REQUEST : 'SAVE_LIST_REQUEST'
}
